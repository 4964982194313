// ProfilePage.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axios_instance';
import { CleaningServices } from '@mui/icons-material';
import JSZip from 'jszip';
import Button from 'react-bootstrap/Button';
import './profile.css'
import { MdOutlineFileDownload } from "react-icons/md";
import { IoDownload } from 'react-icons/io5';
import { AiTwotoneDelete } from "react-icons/ai";
import { MdFileDownload } from "react-icons/md";
const Profile = () => {
    // Define documentsRequired with default values for 'India'


    const [isLoading, setIsLoading] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [show, setShow] = useState(false);
    const [editedProfile, setEditedProfile] = useState({}); // State to manage edited profile data
    const [contentEditable, setcontentEditable] = useState(false)
    const [profileData, setProfileData] = useState(null);
    const [profileData1, setProfileData1] = useState(null);
    const [profileData2, setProfileData2] = useState(null);
    const [historyData, setHistoryData] = useState([]);
    const [promotedDate, setPromotedDate] = useState(null);
    const [relocatedDate, setrelocatedDate] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [isHistoryVisible, setIsHistoryVisible] = useState(false);
    const [relievedDate, setRelievedDate] = useState(null);
    const [inactiveDate, setInactiveDate] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [previewImages, setPreviewImages] = useState(false);
    const [isResumeSubmitted, setIsResumeSubmitted] = useState(false);
    const [resumeDetails, setResumeDetails] = useState('');
    const [ndaDetails, setNDADetails] = useState('');
    const [resumeFile, setResumeFile] = useState(null);
    const [ndaFile, setNDAFile] = useState(null);
    const [uploadedDocument, setUploadedDocument] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [Imagedetails, setImagedetails] = useState(null);
    const [EducationalQualifications, setEducationalQualifications] = useState(null);
    const [educationdetails, setEducationdetails] = useState(null);
    const [paySlips, setPaySlips] = useState(null);
    const [paySlipsdetails, setPaySlipsdetails] = useState(null);
    const [isVisibles, setIsVisibles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [documentsRequired, setDocumentsRequired] = useState({
        'profilePhoto': true,
        'resume': true,
        'nda': true,
        'educationalDocuments': true,
        'paySlips': true,
        'otherDocuments': true,
        'backgroundVerificationDoc': true
    });

    const updateDocumentsRequired = (selectedCountry) => {

        switch (selectedCountry) {
            case 'Canada':
                setDocumentsRequired({
                    'profilePhoto': true,
                    'resume': true,
                    'nda': false,
                    'educationalDocuments': true,
                    'paySlips': true,
                    'otherDocuments': true,
                    'workVisa': true,
                    'workPermit': true,
                    'residanceVisa': true,
                    'labourCard': true
                });
                break;
            case 'Dubai':
                setDocumentsRequired({
                    'profilePhoto': true,
                    'resume': true,
                    'nda': false,
                    'educationalDocuments': true,
                    'paySlips': true,
                    'otherDocuments': true,
                    'workVisa': true,
                    'workPermit': true,
                    'residanceVisa': true,
                    'emriatesID': true,
                    'labourCard': true
                });
                break;
            case 'Us':
                setDocumentsRequired({
                    'profilePhoto': true,
                    'resume': true,
                    'nda': false,
                    'educationalDocuments': true,
                    'paySlips': true,
                    'otherDocuments': true,
                    'passportCopy': true,
                    'i_797': true,
                    'i_94Card': true,
                    'experienceLetter': true,
                    'relievingLetter': true,
                    'drivingLicense': true,
                    'ssnNumber': true,
                    'h4Copy': true,
                    'gcCard': true
                });
                break;
            case 'India':
            default:
                setDocumentsRequired({
                    'profilePhoto': true,
                    'resume': true,
                    'nda': true,
                    'educationalDocuments': true,
                    'paySlips': true,
                    'otherDocuments': true
                });
                break;
        }
    };
    const validateName = (value) => {
        if (!namePattern.test(value)) {
            console.warn('Only letters and spaces are allowed in employeeName');
            return false;
        }
        return true;
    };

    const toggleVisibility = () => {
        setIsVisibles(!isVisibles);
    };
    const role = localStorage.getItem('Role');
    const isAdminFinanace = role === 'ADMIN_FINANCE';
    const isAdmin = role === 'ADMIN';
    const isSuperAdmin = role === 'SUPER_ADMIN';
    const isAssociateHR = role === 'ASSOCIATE_HR';
    const isAssociateFinance = role === 'ASSOCIATE_FINANCE';

    const navigate = useNavigate()
    const fileInputRefs = {
        nda: useRef(null),
        educationalDocuments: useRef(null),
        paySlips: useRef(null),
        profilePhoto: useRef(null),
        resume: useRef(null),
        otherDocuments: useRef(null),
        // dubai
        workVisa: useRef(null),
        workPermit: useRef(null),
        residanceVisa: useRef(null),
        emriatesID: useRef(null),
        labourCard: useRef(null),
        // USA
        gcCard: useRef(null),
        passportCopy: useRef(null),
        i_797: useRef(null),
        i_94Card: useRef(null),
        experienceLetter: useRef(null),
        relievingLetter: useRef(null),
        drivingLicense: useRef(null),
        ssnNumber: useRef(null),
        h4Copy: useRef(null),
        backgroundVerificationDoc: useRef(null),

    };

    const downLoadFile = async (employeeId, fileInfo, file, type) => {
        console.log(employeeId);
        console.log(fileInfo);
        console.log(file);

        try {
            setIsLoading(true); // Show the loader
            const bearerToken = localStorage.getItem('Token');
            const jsonData = { fileInfo: fileInfo, file: file, employeeId: employeeId };
            console.log(jsonData);

            fetch(process.env.REACT_APP_IP + `api/getDocument?fileInfo=${fileInfo}&file=${file}&employeeId=${employeeId}`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("Token")}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blobs => {
                    const blob = new Blob([blobs]);
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `${file}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl);
                    setIsLoading(false); // Hide the loader
                })
                .catch(error => {
                    console.error('Error previewing file:', error);
                    setIsLoading(false); // Hide the loader
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false); // Hide the loader
        }
    };




    const handleDelete = async (employeeId, fileType, event) => {
        event.stopPropagation();
        setIsVisible(false)
        setIsLoading(true)
        try {
            // Make an HTTP request to the backend to delete the file
            const response = await axios.post(process.env.REACT_APP_IP + 'api/deleteDocument', null, {
                params: {
                    fileInfo: fileType,
                    employeeId: employeeId,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('Token')}`,
                },
            }
            );

            // Check the response from the server
            if (response.status === 200) {
                // Update state or perform any additional actions if needed
                setIsLoading(false)
                console.log('File deleted successfully');
                toast.success("File deleted successfully", { position: toast.POSITION.TOP_CENTER })
                window.location.reload()

            } else {
                console.error('Failed to delete file');
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error deleting file:', error);
        }

    };
    // for education documents 
    const handleDeleteFile = (id) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
        setUploadFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };
    const handleUpload = async (formData) => {
        setIsLoading(true);


        try {
            // Make an HTTP request to the backend to delete the file
            const response = await axios.post(process.env.REACT_APP_IP + 'api/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('Token')}`,
                },
            }
            );
            console.log("response", response.data)
            // Check the response from the server
            if (response.status === 200) {
                // Update state or perform any additional actions if needed
                setIsLoading(false)
                console.log('Uploaded Successfully');
                toast.success("Uploaded Successfully", { position: toast.POSITION.TOP_CENTER })
                window.location.reload()
            } else {
                console.error('Failed to delete file');

            }
        } catch (error) {
            console.error('Error deleting file:', error);
            setIsLoading(false)
            toast.error(error, { position: toast.POSITION.TOP_CENTER })
        }
    }

    const documentFileTypes = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    const handleFileSelect = (e, fileType) => {
        const file = e.target.files[0];

        const allowedFileTypes = {
            profilePhoto: ['image/png', 'image/jpg', 'image/jpeg'],
            resume: documentFileTypes,
            nda: documentFileTypes,
            educationalDocuments: documentFileTypes,
            paySlips: documentFileTypes,
            otherDocuments: documentFileTypes,
            workVisa: documentFileTypes,
            workPermit: documentFileTypes,
            residanceVisa: documentFileTypes,
            emriatesID: documentFileTypes,
            labourCard: documentFileTypes,
            gcCard: documentFileTypes,
            passportCopy: documentFileTypes,
            i_797: documentFileTypes,
            i_94Card: documentFileTypes,
            experienceLetter: documentFileTypes,
            relievingLetter: documentFileTypes,
            drivingLicense: documentFileTypes,
            ssnNumber: documentFileTypes,
            h4Copy: documentFileTypes,
            backgroundVerificationDoc: documentFileTypes,

        };

        // Check if the fileType is valid and has allowed file types defined
        if (!allowedFileTypes[fileType]) {
            alert(`Invalid document type: ${fileType}.`);
            return;
        }

        // Check if the selected file type is allowed
        if (!allowedFileTypes[fileType].includes(file.type)) {
            alert(`Invalid file type for ${fileType}. Please upload a valid file.`);
            return;
        }

        // Check if the file already exists only for profile photo, resume, and nda
        if (fileType === 'profilePhoto' || fileType === 'Resume' || fileType === 'nda') {
            const existingFiles = selectedEmployee.totalDocuments.find(doc => Object.keys(doc)[0] === fileType);
            if (existingFiles && existingFiles[fileType].length > 0) {
                alert(`${fileType} already exists. Please delete the existing file before uploading a new one.`);
                return;
            }
        }

        // // Check if the profile photo already exists
        // const existingProfilePhotos = selectedEmployee.totalDocuments[1].profilePhoto;
        // if (existingProfilePhotos && existingProfilePhotos.length > 0) {
        //     // Show an error message or prompt to delete existing file
        //     alert('Profile photo already exists. Please delete the existing file before uploading a new one.');
        //     return;
        // }
        // // Check if the Resume already exists
        // const existingResumePhotos = selectedEmployee.totalDocuments[2].resume;
        // if (existingResumePhotos && existingResumePhotos.length > 0) {
        //     // Show an error message or prompt to delete existing file
        //     alert('Resume already exists. Please delete the existing file before uploading a new one.');
        //     return;
        // }
        // // Check if the NDA already exists
        // const existingNdaPhotos = selectedEmployee.totalDocuments[3].nda;
        // if (existingNdaPhotos && existingNdaPhotos.length > 0) {
        //     // Show an error message or prompt to delete existing file
        //     alert('NDA already exists. Please delete the existing file before uploading a new one.');
        //     return;
        // }
        const storedEmployee = sessionStorage.getItem('EmployeeId');
        const formData = new FormData();
        formData.append('fileInfo', fileType);
        formData.append("fileName", file);
        formData.append('employeeId', storedEmployee);

        // Save the document details and upload date based on file type
        if (fileType === 'Resume') {
            setResumeDetails(`${file.name}`);
            setResumeFile(file);
            setPreviewImages(true)
        } else if (fileType === 'nda') {
            setNDADetails(`${file.name}`);
            setNDAFile(file);
        } else if (fileType === 'profilePhoto') {
            setImagedetails(`${file.name}`);
            setSelectedImage(file);
        }
        else if (fileType === 'educationalDocs') {
            setEducationdetails(`${file.name}`);
            setEducationalQualifications(file);
            setUploadedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
            const selectedFiles = Array.from(e.target.files).map((file) => ({
                id: Date.now(),
                name: file.name,
                type: file.type,
            }));
        }
        else if (fileType === 'payslips') {
            setPaySlipsdetails(`${file.name}`);
            setPaySlips(file);
            setUploadFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
            const selectedFiles = Array.from(e.target.files).map((file) => ({
                id: Date.now(),
                name: file.name,
                type: file.type,
            }));

        }
        handleUpload(formData);
    };


    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const handleShow = () => {
        console.log('Handle Show called');
        setEditedProfile({ ...selectedEmployee });
        setShow(true);
        setcontentEditable(true);
        setSelectedLocation(selectedEmployee.location || '');
        setIsVisible(!isVisible);
        console.log(show);
    };

    const uploadClick = (documentType) => {

        fileInputRefs[documentType].current.click();
    };

    const DeleteClick = (documentType) => {
        // setPreviewImages((prevImages) => prevImages.filter((image) => image.id !== id));
        console.log("Delete option was clicked")
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        // Save the document details and upload date
        setResumeDetails(`${file.name}`);

        // Save the uploaded document for further use (display, delete)
        setUploadedDocument(file);
    };

    const handleUploadButtonClick = () => {
        // Trigger the click event of the hidden file input
        fileInputRefs.current.click();
    };


    const handleClose = () => {
        console.log('Handle Close called');
        setcontentEditable(false);
        setShow(false);
    };


    const handleSave = async () => {

        axiosInstance
            .post("api/updateEmployee", editedProfile)
            .then((response) => {
                // Handle success
                console.log("Update successful:", response.data);

                // Show success toast
                toast.success("Profile successfully updated!", { position: toast.POSITION.TOP_CENTER });
                setShowSuccessModal(true);
                console.log(selectedEmployee.country);
                const updatedEmployee = { ...selectedEmployee, country: editedProfile.country };
                setSelectedEmployee(updatedEmployee);
                updateDocumentsRequired(editedProfile.country)
                // window.location.reload();
                // Close the modal after saving changes
                handleClose();
                window.location.reload();

            })
            .catch((error) => {
                // Handle error
                console.error("Update failed:", error);
                // Show error toast
                toast.error("Failed to update profile. Please try again.", { position: toast.POSITION.TOP_CENTER });
            })

    };
    const namePattern = /[^a-zA-Z\s]/g;
    const idPattern = /[^a-zA-Z0-9]/g;
    const emailPattern = /^[a-zA-Z]+@exafluence\.com$/;
    const handleInputChange = ({ name, value }) => {


        try {
            if ((name === 'phoneNumber' || name === 'totalExperience') && /\D/.test(value)) {
                console.warn('Only numbers are allowed in phoneNumber');
                return; // Exit the function if non-numeric characters are found
            }

            if (name === 'country' || name === 'educationalQualification' || name === "department" || name === 'employeeName') {

                if (!namePattern.test(value)) {
                    console.warn('Only String are allowed in Country');
                    return; // Exit the function if non-numeric characters are found
                }
            }

            if (name === 'employeeId') {

                if (!idPattern.test(value)) {
                    console.warn('Only String are allowed in Country');
                    return; // Exit the function if non-numeric characters are found
                }
            }
            if (name === 'emailId') {

                if (!emailPattern.test(value)) {
                    console.warn('Only String are allowed in Country');
                    return; // Exit the function if non-numeric characters are found
                }
            }

            setEditedProfile((prevProfile) => {
                console.log("check handle input change", name, value)
                if (name === 'promotedDate' || name === 'relocatedDate' || name === 'relievedDate' || name === 'inActiveDate()' || name === 'activeDate' || name === 'salaryDate') {
                    return {
                        ...prevProfile,
                        [name]: value,
                    };

                } else if (name === 'certifications' || name === 'primaryTechnicalskills' || name === 'secondaryTechnicalskills' ||name === 'otherTechnicalskills') {
                    const valuesArray = value.split(',').map((item) => item.trim());
                    return {
                        ...prevProfile,
                        [name]: valuesArray,
                    };
                } else if (name === 'bonusType') {
                    return {
                        ...prevProfile,
                        [name]: value,
                    };

                } else if (name === 'employeeStatus') {
                    console.log("value", value)
                    if (value === 'Relieved') {
                        setRelievedDate(new Date());
                        return {
                            ...prevProfile,
                            [name]: value,

                        };

                    } else {
                        return {
                            ...prevProfile,
                            [name]: value,
                        }
                        // If the input is 'status' and the value is 'Relieved', update the relievedDate state

                    };

                }
                else if (name == 'workingType') {
                    return {
                        ...prevProfile,
                        [name]: value,
                    };
                }

                else if (name === 'status') {
                    console.log("value", value)
                    if (value === 'Inactive') {
                        setInactiveDate(new Date());
                        return {
                            ...prevProfile,
                            [name]: value,

                        };
                    } else {
                        return {
                            ...prevProfile,
                            [name]: value,
                        }
                        // If the input is 'status' and the value is 'Relieved', update the relievedDate state

                    };

                } else {
                    return {
                        ...prevProfile,
                        [name]: value,
                    };
                }
            });
            setShowSuccessModal(true); // Show the success modal
        } catch (error) {
            console.error('Update failed:', error);
        }


    };
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        const printContent = document.getElementById('print-content').innerHTML;

        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Profile</title>
                    <!-- Add your styles if needed -->
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    const handleDownloadCSV = () => {
        // Implement CSV download logic here
        // You can use a library like 'jsonexport' or create a custom CSV string
        // For simplicity, let's assume a basic CSV format for selectedEmployee
        const csvData = Object.values(selectedEmployee).join(',');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'profile.csv';
        link.click();
    };

    const handleBack = () => {
        navigate("/dashboard")
    }

    const handleFinancehistory = () => {
        navigate("/finance_history")
    }

    // const formatDate = (dateString) => {
    //     const dateObject = new Date(dateString);
    //     const day = dateObject.getDate().toString().padStart(2, '0');
    //     const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    //     const year = dateObject.getFullYear();

    //     return `${day}/${month}/${year}`;
    //   };

    const handleHistory = () => {
        setIsHistoryVisible(true);
        console.log("employeeId", selectedEmployee.employeeId);
        axiosInstance
            .get("history/employees/" + selectedEmployee.employeeId)
            .then((response) => {
                // Handle success
                console.log("response", response.data.workHistory);

                setHistoryData(response.data.workHistory); // Update state with history data

                setIsHistoryVisible(!isHistoryVisible);
                // Close the modal after saving changes
                handleClose();

                // Scroll to the table with id "history"
                const historyTable = document.getElementById('history');

                if (historyTable) {
                    setIsHistoryVisible(true);
                    historyTable.scrollIntoView({ behavior: 'smooth' });
                }
            })
            .catch((error) => {
                // Handle error
                console.error("Error fetching history:", error);
            });
    };


    const handleResumeSubmission = (event) => {
        // Assume you have some logic to submit the resume and get details
        // For demonstration purposes, let's say you receive details as a string
        const submittedDetails = 'Resume submitted successfully';

        // Update state
        setIsResumeSubmitted(true);
        setResumeDetails(submittedDetails);
    };

    const renderEmployeeDetails = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Designation</th>
                        <th>Promoted Date</th>
                        <th>Location</th>
                        <th>Relocated Date</th>
                        <th>EmployeeStatus</th>
                        <th>Relieved Date</th>
                        <th>Status</th>
                        <th>Inactive / active Date</th>
                        <th>Inactive Duration</th>



                    </tr>
                </thead>
                <tbody style={{ cursor: "pointer" }}>
                    {console.log("historyData", historyData)}
                    {historyData.map((historyItem, index) => (
                        <tr key={historyItem.id}>
                            <td>{historyItem.designation}</td>
                            <td>{formatDate(historyItem.promotedDate)}</td>
                            <td>{historyItem.location}</td>
                            <td>{formatDate(historyItem.relocatedDate)}</td>

                            <td>{historyItem.employeeStatus}</td>
                            {historyItem.employeeStatus === "Relieved" ? (
                                // Conditionally render Relieved Date cell
                                <td>{formatDate(historyItem.relievedDate)}</td>
                            ) : (
                                // Render an empty cell if status is not "Relieved"
                                <td></td>
                            )}
                            <td>{historyItem.status}</td>
                            {historyItem.status === "Inactive" ? (
                                <td>{formatDate(historyItem.inActiveDate)}</td>
                            ) : (
                                <td></td>
                            )}
                            <td>{historyItem.inActivePeriod}</td>

                            {/* <td>{historyItem.inActiveReason}</td> */}
                            {/* Add other table cells based on your response data */}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };
    const formatDate = (dateString) => {
        if (!dateString) {
            return null;
        }

        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    const formatDate_test = (dateString) => {
        return dateString ? new Date(dateString) : null;
    };
    // const downloadAllDocuments = () => {
    //     const zip = new JSZip();

    //     // Iterate through each type of document
    //     const documentTypes = ['profilePhoto', 'resume', 'nda', 'educationalDocuments', 'paySlips'];

    //     documentTypes.forEach((documentType) => {
    //         const documents = selectedEmployee.totalDocuments.find((doc) => doc[documentType]);

    //         if (documents && documents[documentType].length > 0) {
    //             const folder = zip.folder(documentType);

    //             documents[documentType].forEach(async (document, index) => {
    //                 // You need to replace 'base64String' with the actual base64 content of the document
    //                 // You may need to modify your API to return base64 content or fetch it separately
    //                 // You can use the 'axios' library or the browser's 'fetch' API to fetch the content
    //                 const base64String = ''; // Replace with actual base64 content
    //                 try {
    //                     const response = await axios.get(`http://localhost:8080/api/binaryConverter?fileName=${fileName}&employeeId=${employeeId}`, {
    //                         responseType: 'arraybuffer', // Set the responseType to 'arraybuffer' to receive binary data
    //                     });

    //                     // Create a blob from the binary data received
    //                     const blob = new Blob([response.data]);

    //                     // Create a URL for the blob data
    //                     const imageUrl = URL.createObjectURL(blob);

    //                     // Open the image in a new tab
    //                     const newTab = window.open();
    //                     // Resize the image before displaying it
    //                     const maxImageWidth = 500; // Set your desired maximum width
    //                     const img = new Image();

    //                     img.onload = () => {
    //                         const canvas = newTab.document.createElement('canvas');
    //                         const ctx = canvas.getContext('2d');

    //                         const scaleFactor = maxImageWidth / img.width;
    //                         canvas.width = maxImageWidth;
    //                         canvas.height = img.height * scaleFactor;

    //                         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    //                         // Convert the canvas content to a data URL
    //                         const resizedImageUrl = canvas.toDataURL('image/jpeg'); // Adjust format and quality as needed

    //                     };
    //                     img.src = imageUrl;
    //                     // Display the image on the screen or do whatever you need with the binary data
    //                     console.log(imageUrl);
    //                     // setImageUrl(imageUrl);
    //                     // Set the imageUrl state or use it as needed in your component
    //                 } catch (error) {
    //                     console.error('Error fetching binary data:', error);
    //                     // Handle errors here
    //                 }

    //                 // Add each document to the zip folder
    //                 folder.file(`${documentType}_${index + 1}.${document.split('.').pop()}`, base64String, { base64: true });
    //             });
    //         }
    //     });

    //     // Generate the zip file
    //     zip.generateAsync({ type: 'blob' }).then((content) => {
    //         // Create a download link
    //         const link = document.createElement('a');
    //         link.href = URL.createObjectURL(content);
    //         link.download = 'employee_documents.zip';

    //         // Trigger the download
    //         link.click();
    //     });
    // };

    const handleFileNameClick = async (fileName, employeeId) => {
        const fileType = fileName.split('.').pop().toLowerCase();

        const response = await axios.get(process.env.REACT_APP_IP + `api/binaryConverter?fileName=${fileName}&employeeId=${employeeId}`, {
            responseType: 'arraybuffer', // Set the responseType to 'arraybuffer' to receive binary data
        });
        if (['png', 'jpg', 'jpeg'].includes(fileType)) {
            try {

                // Create a blob from the binary data received
                const blob = new Blob([response.data]);

                // Create a URL for the blob data
                const imageUrl = URL.createObjectURL(blob);

                // Open the image in a new tab
                const newTab = window.open();
                // Resize the image before displaying it
                const maxImageWidth = 500; // Set your desired maximum width
                const img = new Image();

                img.onload = () => {
                    const canvas = newTab.document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const scaleFactor = maxImageWidth / img.width;
                    canvas.width = maxImageWidth;
                    canvas.height = img.height * scaleFactor;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Convert the canvas content to a data URL
                    const resizedImageUrl = canvas.toDataURL('image/jpeg'); // Adjust format and quality as needed


                    newTab.document.write(`
            <html>
              <head>
                <title>Image</title>
                <style>
                  body { margin: 0; padding: 0; }
                  img { max-width: 100%; }
                  .download-container { position: fixed; top: 0; width: 100%; text-align: right; background-color: #f0f0f0; padding: 10px; }
                  .download-button { padding: 8px 16px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 4px; font-weight: bold; }
                </style>
              </head>
              <body>
                <div class="download-container">
                  <a class="download-button" href="${resizedImageUrl}" download="${fileName}">Download Image</a>
                </div>
                <img src="${resizedImageUrl}" alt="Profile Photo" />
              </body>
            </html>
          `);

                    // newTab.document.write(`<html><head><title>Image</title></head><body><img src="${imageUrl}" alt="Profile Photo" style="max-width: 100%;" /></body></html>`);

                    // // Create a download button
                    // const downloadButton = newTab.document.createElement('a');
                    // downloadButton.href = imageUrl;
                    // downloadButton.download = fileName;
                    // downloadButton.innerHTML = 'Download Image';
                    // newTab.document.body.appendChild(downloadButton);

                    // Clean up the URL when the new tab is closed
                    newTab.onbeforeunload = () => {
                        URL.revokeObjectURL(imageUrl);
                        URL.revokeObjectURL(resizedImageUrl);
                    };

                };
                img.src = imageUrl;
                // Display the image on the screen or do whatever you need with the binary data
                console.log(imageUrl);
                // setImageUrl(imageUrl);
                // Set the imageUrl state or use it as needed in your component
            } catch (error) {
                console.error('Error fetching binary data:', error);
                // Handle errors here
            }
        } else if (fileType === 'pdf') {
            try {
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);

                const newTab = window.open();

                newTab.document.write(`
                    <html>
                        <head>
                            <title>PDF Document</title>
                        </head>
                        <body>
                            <embed width="100%" height="100%" src="${pdfUrl}" type="application/pdf">
                        </body>
                    </html>
                `);

                newTab.onbeforeunload = () => {
                    URL.revokeObjectURL(pdfUrl);
                };
            } catch (error) {
                console.error('Error handling PDF:', error);
            }
        } else if (['doc', 'docx'].includes(fileType)) {
            console.log("fileType", fileType)
            try {
                // Create a blob from the binary data received
                const docBlob = new Blob([response.data], { type: 'application/msword' }); // Use 'application/msword' for .doc files

                // Create a URL for the blob data
                const docUrl = URL.createObjectURL(docBlob);

                // Open the document in a new tab
                const newTab = window.open();

                // Write the HTML content to display the document
                newTab.document.write(`
                        <html>
                            <head>
                                <title>Word Document</title>
                            </head>
                            <body>
                                <iframe width="100%" height="100%" src="${docUrl}" type="application/msword"></iframe>
                            </body>
                        </html>
                    `);

                // Clean up the URL when the new tab is closed
                newTab.onbeforeunload = () => {
                    URL.revokeObjectURL(docUrl);
                };
            } catch (error) {
                console.error('Error handling Word document:', error);
            }
        } else {
            // Handle other document types or show an error message
            console.error('Unsupported document type:', fileType);
        }


    };


    useEffect(() => {
        // Retrieve selected employee details from sessionStorage
        var storedEmployee = sessionStorage.getItem('EmployeeId');
        console.log(storedEmployee)
        const token = localStorage.getItem('Token');
        // Fetch employee details from the database and update the state
        const fetchData = async () => {
            try {
                // Retrieve the token from local storage

                if (!token) {
                    navigate("/login")
                }

                if (storedEmployee == null) {
                    navigate("/dashboard")
                }
                console.log(storedEmployee)
                const response = await axiosInstance.get('api/getEmployeeById/' + storedEmployee); // replace with your actual API endpoint
                console.log("storedEmployee", response.data)
                console.log("response", response)
                setSelectedEmployee(response.data);
                updateDocumentsRequired(response.data.country)
            } catch (error) {
                console.log("errror", error);

                // toast.error("Token Expired .......Please Login again....!", { position: toast.POSITION.TOP_CENTER })
                // sessionStorage.clear();
                // localStorage.clear();
                // localStorage.setItem("islogged", false);
                // navigate("/login")


            }
        };

        fetchData();



    }, []);

    return (


        <div className="container mt-5 mb-3 w-75" style={{ boxShadow: "0px 0px 5px #000", borderRadius: "5px", padding: "2rem", backgroundColor: "white" }}>
            {isLoading && (
                <div className="loader">
                    <div className="circular-loader"></div>
                </div>
            )}
            <div className="text-center">
                <div className="rounded-circle bg-primary text-white d-inline-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px' }}>
                    <h1 style={{ margin: 0 }}>{selectedEmployee && selectedEmployee.employeeName ? selectedEmployee.employeeName.charAt(0) : ''}</h1>
                </div>
                <h2>{selectedEmployee && selectedEmployee.employeeName}</h2>
            </div>
            {isAssociateHR ? (
                <div className="d-flex justify-content-end mb-3" style={{}}>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            + More Options
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={handleHistory}>History</Dropdown.Item> */}
                            {/* <Dropdown.Item onClick={handlePrint}>Print</Dropdown.Item> */}

                            <Dropdown.Item onClick={() => { handleShow(); setcontentEditable(!contentEditable); }}>Edit Profile</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => { handleShow(); setcontentEditable(!contentEditable); }}>Financial Info </Dropdown.Item> */}



                            {/* <Dropdown.Item onClick={handleDownloadCSV}>Download CSV</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>

                </div>) : (<div className="d-flex justify-content-end mb-3" style={{}}>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            + More Options
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleHistory}>History</Dropdown.Item>
                            <Dropdown.Item onClick={handlePrint}>Print</Dropdown.Item>

                            <Dropdown.Item onClick={() => { handleShow(); setcontentEditable(!contentEditable); }}>Edit Profile</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => { handleShow(); setcontentEditable(!contentEditable); }}>Financial Info </Dropdown.Item> */}



                            <Dropdown.Item onClick={handleDownloadCSV}>Download CSV</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>)
            }

            <div className="mt-3 " style={{ textAlign: 'left' }} id="print-content">

                {selectedEmployee && (

                    <div style={{ marginLeft: '7rem', backgroundColor: "white" }}>
                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Employee ID : </strong>
                                    
                        
                                        <span>{selectedEmployee.employeeId}</span>
                                 
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div>
                                    <strong>Employee Name: </strong>
                                    {isAdmin || isSuperAdmin ? (
                                        <span
                                            contentEditable={contentEditable}
                                            className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                            onInput={(e) => {
                                                let inputValue = e.target.textContent.replace(/[^a-zA-Z\s]/g, ''); // Remove non-digit characters

                                                e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                                handleInputChange({ name: 'employeeName', value: inputValue });
                                            }}
                                        >
                                            {selectedEmployee.employeeName}
                                        </span>
                                    ) : (
                                        <span>{selectedEmployee.employeeName}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Email ID : </strong>
                                    {isAdmin || isSuperAdmin ? (
                                        <span
                                            contentEditable={contentEditable}
                                            className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                            onInput={(e) => {
                                                let inputValue = e.target.textContent.replace(/^[a-zA-Z]+@exafluence\.com$/g, ''); // Remove non-digit characters

                                                e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                                handleInputChange({ name: 'emailId', value: inputValue });
                                            }}
                                        >
                                            {selectedEmployee.emailId}
                                        </span>
                                    ) : (
                                        <span>{selectedEmployee.emailId}</span>
                                    )}

                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div><strong>Date Of Birth : </strong>
                                    {(isAdmin || isSuperAdmin) && contentEditable ? (
                                        <DatePicker
                                            selected={formatDate_test(editedProfile.dateOfBirth)}
                                            onChange={(date) => handleInputChange({ name: 'dateOfBirth', value: date })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            className="form-control"
                                        />
                                    ) : (
                                        <span>{formatDate(selectedEmployee.dateOfBirth)}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Date Of Joining : </strong>
                                    {(isAdmin || isSuperAdmin) && contentEditable ? (
                                        <DatePicker
                                            selected={formatDate_test(editedProfile.dateOfJoining)}
                                            onChange={(date) => handleInputChange({ name: 'dateOfJoining', value: date })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            className="form-control"
                                        />
                                    ) : (
                                        <span>{formatDate(selectedEmployee.dateOfJoining)}</span>
                                    )}



                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div><strong>Experience : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} 
                                 onInput={(e) => {
                                    let inputValue = e.target.textContent.replace(/[^0-9.]/g, '');  // Allow digits and '.'
                                    if (inputValue.length < 2 ) {
                                        inputValue = inputValue.substring(0, 2); // Limit string to first 10 digits
                                    }
                                    e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                    handleInputChange({ name: 'totalExperience', value: inputValue });
                                }}
                                > {selectedEmployee.totalExperience}</span></div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Department : </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        style={{ cursor: contentEditable ? 'text' : 'default' }}
                                        onInput={(e) => {
                                            let inputValue = e.target.textContent.replace(/[^a-zA-Z\s]/g, ''); // Remove non-digit characters

                                            e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                            handleInputChange({ name: 'department', value: inputValue });
                                        }}
                                    >
                                        {selectedEmployee.department}
                                    </span>


                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div><strong>Education Qualification: </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        style={{ cursor: contentEditable ? 'text' : 'default' }}
                                        onInput={(e) => {
                                            let inputValue = e.target.textContent.replace(/[^a-zA-Z\s]/g, ''); // Remove non-digit characters

                                            e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                            handleInputChange({ name: 'educationalQualification', value: inputValue });
                                        }}
                                    >
                                        {selectedEmployee.educationalQualification}
                                    </span>


                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Country : </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        style={{ cursor: contentEditable ? 'text' : 'default' }}
                                        onInput={(e) => {
                                            let inputValue = e.target.textContent.replace(/[^a-zA-Z\s]/g, ''); // Remove non-digit characters

                                            e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                            handleInputChange({ name: 'country', value: inputValue });
                                        }}

                                    >
                                        {selectedEmployee.country}
                                    </span>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div><strong>Phone Number </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        style={{ cursor: contentEditable ? 'text' : 'default' }}
                                        onInput={(e) => {
                                            let inputValue = e.target.textContent.replace(/\D/g, ''); // Remove non-digit characters
                                            if (inputValue.length > 10) {
                                                inputValue = inputValue.substring(0, 10); // Limit string to first 10 digits
                                            }
                                            e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                                            handleInputChange({ name: 'phoneNumber', value: inputValue });
                                        }}
                                    >
                                        {selectedEmployee.phoneNumber}
                                    </span>


                                </div>
                            </div>
                        </div>


                        <li className="list-group-item">
                            <div className="row mb-4">
                                <div className="col-md-6 ">
                                    <strong>Designation: </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        style={{ cursor: contentEditable ? 'text' : 'default' }}
                                        onInput={(e) => handleInputChange({ name: 'designation', value: e.target.textContent })}
                                    >
                                        {selectedEmployee.designation}
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <strong>Location: </strong>
                                    {contentEditable ? (
                                        <select
                                            className="editable-field form-select"
                                            style={{ width: '150px' }}
                                            value={editedProfile.location}
                                            onChange={(e) => handleInputChange({ name: 'location', value: e.target.value })}
                                        >
                                            <option value="">Select Location</option>
                                            <option value="tirupati">Tirupati</option>
                                            <option value="dubai">Dubai</option>
                                            <option value="bangalore">Bangalore</option>
                                            <option value="chennai">Chennai</option>
                                            <option value="us">US</option>
                                        </select>
                                    ) : (
                                        <span>{selectedEmployee.location}</span>
                                    )}
                                </div>
                            </div>

                            {contentEditable && (
                                <div className="row mb-4">
                                    <div className="col-md-6 ">
                                        <strong>Promoted Date: </strong>{' '}
                                        <DatePicker
                                            selected={formatDate_test(editedProfile.promotedDate)}
                                            onChange={(date) => handleInputChange({ name: 'promotedDate', value: date })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <strong>Relocated On: </strong>{' '}
                                        <DatePicker
                                            selected={formatDate_test(editedProfile.relocatedDate)}
                                            onChange={(date) => handleInputChange({ name: 'relocatedDate', value: date })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            )}

                        </li>



                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Onsite / Offshore : </strong> {selectedEmployee.onsiteOffshore}</div>
                            </div>
                            <div className='col-md-6'>
                                <div><strong>Induction and Onboarding: </strong> {selectedEmployee.inductionOnboarding ? 'Yes' : 'No'}</div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className='col-md-6'>
                                {/* <li><strong>Technical Skills : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'technicalskills', value: e.target.textContent })}> {selectedEmployee.technicalskills}</span></li> */}
                                <div>
                                    <strong>Primary Technical Skills: </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        onInput={e => {
                                            const updatedSkills = e.target.textContent.trim();
                                            handleInputChange({
                                                name: 'primaryTechnicalskills',
                                                value: updatedSkills
                                            });
                                        }}
                                    >
                                        {Array.isArray(selectedEmployee.primaryTechnicalskills) ?
                                            selectedEmployee.primaryTechnicalskills.join(', ') :
                                            selectedEmployee.primaryTechnicalskills
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                {/* <li><strong>Technical Skills : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'technicalskills', value: e.target.textContent })}> {selectedEmployee.technicalskills}</span></li> */}
                                <div>
                                    <strong>Secondary Technical Skills: </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        onInput={e => {
                                            const updatedSkills = e.target.textContent.trim();
                                            handleInputChange({
                                                name: 'secondaryTechnicalskills',
                                                value: updatedSkills
                                            });
                                        }}
                                    >
                                        {Array.isArray(selectedEmployee.secondaryTechnicalskills) ?
                                            selectedEmployee.secondaryTechnicalskills.join(', ') :
                                            selectedEmployee.secondaryTechnicalskills
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-md-6'>
                                {/* <li><strong>Technical Skills : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'technicalskills', value: e.target.textContent })}> {selectedEmployee.technicalskills}</span></li> */}
                                <div>
                                    <strong>Other Technical Skills: </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        onInput={e => {
                                            const updatedSkills = e.target.textContent.trim();
                                            handleInputChange({
                                                name: 'otherTechnicalskills',
                                                value: updatedSkills
                                            });
                                        }}
                                    >
                                        {Array.isArray(selectedEmployee.otherTechnicalskills) ?
                                            selectedEmployee.otherTechnicalskills.join(', ') :
                                            selectedEmployee.otherTechnicalskills
                                        }
                                    </span>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                {/* <li><strong> Certifications : </strong> <span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'certifications', value: e.target.textContent })}>{selectedEmployee.certifications}</span></li> */}
                                <div>
                                    <strong>Certifications: </strong>
                                    <span
                                        contentEditable={contentEditable}
                                        className={`p-2 ${contentEditable ? 'editable-field' : ''}`}
                                        onInput={e => {
                                            const updatedSkills = e.target.textContent.trim();
                                            handleInputChange({
                                                name: 'certifications',
                                                value: updatedSkills
                                            });
                                        }}
                                    >
                                        {Array.isArray(selectedEmployee.certifications) ?
                                            selectedEmployee.certifications.join(', ') :
                                            selectedEmployee.certifications
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <div><strong>Background Verification : </strong> {selectedEmployee.backgroundVerification ? 'Yes' : 'No'}</div>
                            </div>
                            <div className='col-md-6'>
                                <div><strong>Statutory : </strong> {selectedEmployee.statutory && selectedEmployee.statutory.map((item, index) => (
                                    <span key={index}>
                                        {item}
                                        {index !== selectedEmployee.statutory.length - 1 && ', '}
                                    </span>
                                ))}</div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <strong>Employee Status: </strong>
                                {contentEditable ? (
                                    <select
                                        className="editable-field form-select"
                                        value={editedProfile.employeeStatus}
                                        style={{ width: '150px' }}
                                        onChange={(e) => handleInputChange({ name: 'employeeStatus', value: e.target.value })}
                                    >
                                        <option value="Working">Working</option>
                                        <option value="Relieved">Relieved</option>
                                    </select>
                                ) : (
                                    <span>{selectedEmployee.employeeStatus}</span>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <strong> Status: </strong>
                                {contentEditable ? (
                                    <select
                                        className="editable-field form-control"
                                        style={{ width: '150px' }}
                                        value={editedProfile.status}
                                        onChange={(e) => handleInputChange({ name: 'status', value: e.target.value })}
                                    >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                ) : (
                                    <span>{selectedEmployee.status}</span>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-md-6'>
                                <strong>Working Type:</strong>
                                {contentEditable ? (
                                    <select
                                        className="editable-field form-select"
                                        value={editedProfile.workingType}
                                        style={{ width: '150px' }}
                                        onChange={(e) => handleInputChange({ name: 'workingType', value: e.target.value })}
                                    >
                                        <option value={selectedEmployee.workingType}>{selectedEmployee.workingType}</option>
                                        <option value="WFO">WFO</option>
                                        <option value="Hybrid">Hybrid</option>

                                    </select>
                                ) : (
                                    <span> {selectedEmployee.workingType}</span>
                                )}
                            </div>

                        </div>
                        <div className="row mb-4">
                            <div className='col-md-6'>
                                {editedProfile.employeeStatus === 'Relieved' && contentEditable && (
                                    <div className="col-md-6 mt-3 mt-md-0">
                                        <strong>Relieved Date: </strong>{' '}
                                        <DatePicker
                                            selected={formatDate_test(editedProfile.relievedDate)}
                                            onChange={(date) => handleInputChange({ name: 'relievedDate', value: date })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            className="form-control"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6'>
                                {editedProfile.status === 'Inactive' && contentEditable && (
                                    <div className="row mt-3">
                                        <div className='col-md-6 mb-3 mt-3'>
                                            <strong> Date: </strong>{' '}
                                            <DatePicker
                                                selected={formatDate_test(editedProfile.inActiveDate)}
                                                onChange={(date) => handleInputChange({ name: 'inActiveDate', value: date })}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                            />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <strong> Reason: </strong>{' '}
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inActiveReason"
                                                name="inActiveReason"
                                                value={selectedEmployee.inActiveReason}
                                                onChange={e => handleInputChange({ name: 'inActiveReason', value: e.target.value })}
                                                placeholder="Enter Reason"
                                            />
                                        </div>
                                    </div>
                                )}
                                {editedProfile.status === 'Active' && contentEditable && (
                                    <div className="row mt-3">
                                        <div className='col-md-6 mb-3 mt-3'>
                                            <strong> Date: </strong>{' '}
                                            <DatePicker
                                                selected={formatDate_test(editedProfile.activeDate)}
                                                onChange={(date) => handleInputChange({ name: 'activeDate', value: date })}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Add a dropdown for 'Status' */}

                    </div>

                )}
            </div>

            <div style={{ textAlign: 'left' }}>
                {/* <hr style={{ width: '80%', margin: '20px auto', backgroundColor: '#333' }} /> */}
                {/* <div>
                    <h3 className='text-center'>Financial Details</h3>
                    {isAdminFinanace || isSuperAdmin &&
                        <div className="d-flex justify-content-end mb-3" >
                            <Button variant="outline-secondary" onClick={handleFinancehistory} ><b>Financial History</b></Button>
                        </div>
                    }

                  
                    <div style={{ marginLeft: '7rem', backgroundColor: "white" }}>
                        {selectedEmployee && (
                            <>
                                <div className='row'>

                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Basic Salary : </strong>

                                        <span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'salary', value: e.target.textContent })}> {selectedEmployee.salary}</span>
                                    </div>
                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong> Date: </strong>
                                        {contentEditable ? (

                                            <DatePicker
                                                selected={formatDate_test(editedProfile.salaryDate)}
                                                onChange={(date) => handleInputChange({ name: 'salaryDate', value: date })}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                                className="form-control" />
                                        ) : (
                                            <span>
                                                {formatDate(selectedEmployee.salaryDate)}
                                            </span>
                                        )}
                                    </div>

                                </div>
                                <div className='row'>

                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Hike :</strong>
                                        <span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'hike', value: e.target.textContent })}> {selectedEmployee.hike}</span>
                                    </div>
                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Date : </strong>
                                        {contentEditable ? (

                                            <DatePicker
                                                selected={formatDate_test(editedProfile.hikeDate)}
                                                onChange={(date) => handleInputChange({ name: 'hikeDate', value: date })}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                                className="form-control" />
                                        ) : (
                                            <span>
                                                {formatDate(selectedEmployee.hikeDate)}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Amount : </strong>
                                        <span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'hikeAmount', value: e.target.textContent })}> {selectedEmployee.hikeAmount}</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Bonus Type : </strong>
                                        {contentEditable ? (
                                            <select
                                                className="editable-field form-control"
                                                style={{ width: '150px' }}
                                                value={editedProfile.bonusType}
                                                onChange={(e) => handleInputChange({ name: 'bonusType', value: e.target.value })}
                                            >
                                                <option value="Q1">Q1</option>
                                                <option value="Q2">Q2</option>
                                            </select>
                                        ) : (
                                            <span>{selectedEmployee.bonusType}</span>
                                        )}
                                    </div>
                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Date : </strong>
                                        {contentEditable ? (

                                            <DatePicker
                                                selected={formatDate_test(editedProfile.bonusDate)}
                                                onChange={(date) => handleInputChange({ name: 'bonusDate', value: date })}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                                className="form-control" />
                                        ) : (
                                            <span>
                                                {formatDate(selectedEmployee.bonusDate)}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-md-4 mt-2 mb-4'>
                                        <strong>Amount : </strong>
                                        <span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'bonusAmount', value: e.target.textContent })}> {selectedEmployee.bonusAmount}</span>
                                    </div>
                                </div>
                            </>
                        )}</div>
                </div> */}

                <div>
                    <hr style={{ width: '80%', margin: '20px auto', backgroundColor: '#333' }} /><div><h3 className='text-center'>Upload Documents</h3>
                        {/* <button onClick={downloadAllDocuments} className='btn btn-primary' style={{ width: "100%" }}>Download All Documents</button> */}
                        <div style={{ marginLeft: '7rem', backgroundColor: "white" }}>
                            {selectedEmployee && (
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            {isLoading && (
                                                <div className="loader">
                                                    <div className="circular-loader"></div>
                                                </div>
                                            )}
                                            {selectedEmployee && Object.keys(selectedEmployee).map((employeeKey, employeeIndex) => {
                                                if (employeeKey === 'totalDocuments') {
                                                    return (
                                                        <React.Fragment key={employeeIndex}>
                                                            {selectedEmployee.totalDocuments && selectedEmployee.totalDocuments.length > 0 && selectedEmployee.totalDocuments.map((document, docIndex) => {
                                                                const docType = Object.keys(document)[0];
                                                                const docDetails = document[docType];
                                                                const documentExists = Object.keys(documentsRequired).includes(docType);
                                                                if (documentExists) {
                                                                    return (
                                                                        <div key={docIndex} className='row d-flex' style={{ padding: '30px' }}>

                                                                            <div className='col-3'>
                                                                                <p>{docType}</p>
                                                                            </div>
                                                                            <div className='col-6 d-flex flex-column' style={{ maxHeight: '140px', overflowY: 'auto' }}>
                                                                                {docDetails.length > 0 ? (
                                                                                    docDetails.map((doc, innerIndex) => (
                                                                                        <div key={innerIndex} className="d-flex col-4 flex-row" style={{ margin: '0 5px' }}>
                                                                                            <h5>{doc.fileName}</h5>
                                                                                            {!(isAssociateFinance || isAssociateHR) &&
                                                                                                <MdOutlineFileDownload
                                                                                                    size={30}
                                                                                                    onClick={() => {
                                                                                                        downLoadFile(selectedEmployee.employeeId, docType, doc.fileName);
                                                                                                    }}
                                                                                                    title='Download'
                                                                                                />
                                                                                            }

                                                                                            <AiTwotoneDelete
                                                                                                size={30}
                                                                                                onClick={(event) => handleDelete(selectedEmployee.employeeId, doc.fileName, event)}
                                                                                            />


                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="d-flex col-12 justify-content-center align-items-center">
                                                                                        <p>Please Upload</p>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            <div className='col-3'>
                                                                                <input
                                                                                    type="file"
                                                                                    ref={fileInputRefs[docType]} // Access the appropriate ref based on doc.docType
                                                                                    accept={
                                                                                        docType === 'profilePhoto' ? '.png,.jpg,.jpeg' : '.pdf,.doc,.docx'
                                                                                    }
                                                                                    style={{ display: 'none' }}
                                                                                    onChange={(e) => handleFileSelect(e, docType)} // Pass doc.docType to handleFileSelect
                                                                                    required
                                                                                />

                                                                                <div>
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => console.log(fileInputRefs[docType].current.click())} // Access the input element by docType
                                                                                        className="btn bg-primary text-white"
                                                                                    >
                                                                                        + Upload
                                                                                    </button>

                                                                                </div>


                                                                            </div>


                                                                        </div>


                                                                    );
                                                                }
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                } else {
                                                    // Handle other properties of selectedEmployee here if needed
                                                    return null;
                                                }
                                            })}



















                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>

                    </div>



                </div>


                {isHistoryVisible && (
                    <div id='history'>
                        <hr style={{ width: '80%', margin: '20px auto', backgroundColor: '#333' }} />
                        <div className="row mb-3 pt-3 " >
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title text-center">Employee History</h5>
                                        {renderEmployeeDetails()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='container'>
                    <div className='row text-center'>
                        <div className='col-md-6'>
                            <button onClick={handleSave} className='btn btn-primary' style={{ width: "100%" }}>Save Changes</button>
                        </div>
                        <div className='col-md-6'>
                            <button onClick={handleBack} className='btn btn-primary' style={{ width: "100%" }}>Back To Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default Profile;